export const SITE = process.env.GATSBY_SITE || 'openfit';
export const CART_STORAGE_KEY = `${SITE}-cart`;
export const CART_COUNT_COOKIE = `${SITE}-cart-count`;
export const DEFAULT_BUY_BUTTON_ACTION =
  process.env.GATSBY_FORCE_ADD_TO_CART === 'true' ? 'cart' : 'checkout';
export const BUY_FROM_AMAZON = 'Buy from Amazon';
export const AMAZON_BASE_URL = 'https://www.amazon.com/stores/page';
export const AMAZON_HOME_PAGE_URL = `${AMAZON_BASE_URL}/8C1EE93C-93A5-4B47-BA94-31F0F45E340B?channel=redirect`;
export const AMAZON_WHEY_PROTEIN_PAGE_URL = `${AMAZON_BASE_URL}/304878FF-0A10-4150-B3FC-9A017B5E9953?channel=redirectwhey`;
export const AMAZON_PLANT_PROTEIN_PAGE_URL = `${AMAZON_BASE_URL}/18397E16-6A3B-4179-967E-272D323DB799?channel=redirectplant`;
export const AMAZON_PRE_WORKOUT_PAGE_URL = `${AMAZON_BASE_URL}/56D1D42D-7CAC-44AC-B3C3-4513615A3C6E?channel=redirectpre`;
export const AMAZON_HYDRATION_MIX_PAGE_URL = `${AMAZON_BASE_URL}/A5723017-2356-4ED9-9DE0-88220B4D064A?channel=redirecthyd`;
export const AMAZON_SUPERFOOD_GREENS_PAGE_URL = `${AMAZON_BASE_URL}/402CAA81-28AD-4E5E-BD72-50705DC6764B?channel=redirectgreens`;
export const AMAZON_SKU_REDIRECTS: Record<string, string> = {
  BAGWPCH300065: AMAZON_WHEY_PROTEIN_PAGE_URL,
  BAGWPVA300065: AMAZON_WHEY_PROTEIN_PAGE_URL,
  BAGPPCH300060: AMAZON_PLANT_PROTEIN_PAGE_URL,
  BAGPPVA300060: AMAZON_PLANT_PROTEIN_PAGE_URL,
  BAGPWSL300056: AMAZON_PRE_WORKOUT_PAGE_URL,
  BAGPWTF300056: AMAZON_PRE_WORKOUT_PAGE_URL,
  BAGHYMB300040: AMAZON_HYDRATION_MIX_PAGE_URL,
  BAGHYLL300040: AMAZON_HYDRATION_MIX_PAGE_URL,
  BAGGRSB300060: AMAZON_SUPERFOOD_GREENS_PAGE_URL,
};
