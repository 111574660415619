import {
  Maybe,
  SanityImage,
  SanityVariantOptionFlavor,
  SanityVariantOptionServingSize,
  SanityVariantOptionServingType,
} from 'graphql-types';
import {
  VariantGroupSelectorProps,
  SupplementProductVariant,
  ProductVariant,
  SupplementProductVariantMap,
  OpenfitProduct,
  VariantOptions,
  VariantOptionProps,
  SupplementOptions,
} from './Product.context.model';

export function getProductContextData(
  product: OpenfitProduct,
  initialSku?: string
) {
  let initialVariantGroup: VariantGroupSelectorProps | undefined = undefined;

  const productVariantMap: SupplementProductVariantMap = {};
  const variantGroupSelectors: VariantGroupSelectorProps[] = [];

  product?.variants?.forEach(
    (variant: NonNullable<OpenfitProduct['variants']>[0]) => {
      let productSelector: VariantGroupSelectorProps = undefined as any;

      if (variant) {
        const {
          initialSupplementVariantGroup,
          supplementProduct,
        } = getFlattenedVariant(
          variant,
          productVariantMap,
          initialVariantGroup,
          initialSku
        );

        productSelector = supplementProduct;
        initialVariantGroup =
          initialSupplementVariantGroup || initialVariantGroup;
      }

      if (!productSelector) {
        productSelector = {
          description: product?.name,
        };
      }

      // Verify product hasn't already been found
      const isUnique = variantGroupSelectors.find(
        (uniqueProduct) =>
          uniqueProduct.description === productSelector.description
      );

      if (!isUnique) {
        variantGroupSelectors.push(productSelector);
      }
    }
  );

  initialVariantGroup = initialVariantGroup || variantGroupSelectors[0];
  const initialVariantOptions = getSupplementVariantOptions(
    productVariantMap,
    initialVariantGroup
  );

  const initialSelectedVariantOption = getVariantOption(
    initialVariantOptions.options,
    initialSku
  );

  const initialSubscriptionOptions =
    initialSelectedVariantOption &&
    productVariantMap[initialSelectedVariantOption.variantType]
      .subscriptionOptions;

  const subscriptionOption = getSubscriptionOption(
    initialSubscriptionOptions,
    initialSku
  );

  const initialSubscriptionSelection = {
    isSelected:
      initialSku && initialSku === initialSelectedVariantOption.sku
        ? false
        : Boolean(initialSubscriptionOptions?.length),
    value: subscriptionOption,
  };

  return {
    variantGroupSelectors,
    initialVariantGroup,
    productVariantMap,
    initialSelectedVariantOption,
    initialVariantOptions,
    initialSubscriptionSelection,
    initialSubscriptionOptions,
    initialSku,
  };
}

export function getSubscriptionOption(
  subscriptions: SupplementProductVariant[] = [],
  sku?: string,
  currentSubscription?: ProductVariant
) {
  return (
    (currentSubscription &&
      subscriptions.find(
        (option) =>
          option.interval === currentSubscription.interval &&
          option.intervalCount === currentSubscription.intervalCount
      )) ||
    (sku && subscriptions.find((option) => option.sku === sku)) ||
    subscriptions?.[0]
  );
}

/**
 * Returns the variant option that matches the sku or one of the skus related subscriptionSkus
 * @param {VariantOptionProps[]} options
 * @param {string} sku
 */
export function getVariantOption(
  options: VariantOptionProps[],
  sku?: string,
  oldVariant?: VariantOptionProps
) {
  if (!sku) {
    return options[0];
  }

  const { servingType, size, text } = oldVariant || {};

  return (
    // Find by text
    (text && options.find((option) => option.text === text)) ||
    // Find by servingType/size
    (servingType &&
      size &&
      options.find(
        (option) => option.size === size && option.servingType === servingType
      )) ||
    // Find by servingType
    (servingType &&
      options.find((option) => option.servingType === servingType)) ||
    // Find by size
    (size && options.find((option) => option.size === size)) ||
    // Find by sku or subscription sku
    (sku &&
      options.find(
        (option) => option.sku === sku || option.subscriptionSkus.includes(sku)
      )) ||
    // Return first one
    options[0]
  );
}

export function getSupplementVariantOptions(
  productVariants: SupplementProductVariantMap,
  selectedProduct: VariantGroupSelectorProps
): VariantOptions {
  return {
    options: Object.keys(productVariants)
      .filter((key) => {
        const flavorName =
          productVariants[key].standard?.flavor?.name ||
          productVariants[key].subscriptionOptions?.[0]?.flavor?.name;
        return flavorName === selectedProduct.description;
      })
      .map((key) => {
        const variant =
          productVariants[key].standard ||
          productVariants[key].subscriptionOptions[0];
        const subscriptionSkus = productVariants[key].subscriptionOptions.map(
          (option) => option.sku
        );
        return {
          ...variant,
          variantType: key,
          subscriptionSkus,
        };
      }),
  };
}

export function pluralizeInterval(type: string, intervalCount = 1) {
  return `${intervalCount} ${type
    .substring(0, 1)
    .toUpperCase()}${type.substring(1)}s`;
}

/**
 * Flattens maps variantOptions list to props on the variant
 * @param {ProductVariant} variant
 * @param {boolean} updateVariant - default true. determine if variant is updated with props
 * @returns {SupplementOptions}
 */
export function flattenSupplementVariantOptions(
  variant: ProductVariant,
  updateVariant = true
): SupplementOptions {
  const supplementOptionObject = {};
  variant.variantOptions?.reduce(
    (accumulator: SupplementProductVariant, curr) => {
      if (
        curr &&
        (curr?.__typename === 'SanityVariantOptionFlavor' ||
          ((curr as unknown) as SanityVariantOptionFlavor)?._type ===
            'variantOptionFlavor')
      ) {
        accumulator.flavor = {
          colorSwatch:
            ((curr as unknown) as SanityVariantOptionFlavor).colorSwatch?.hex ||
            '#24A3E3',
          name: ((curr as unknown) as SanityVariantOptionFlavor)?.name || '',
        };
      }

      if (
        curr &&
        (curr?.__typename === 'SanityVariantOptionServingSize' ||
          ((curr as unknown) as SanityVariantOptionServingSize)?._type ===
            'variantOptionServingSize')
      ) {
        accumulator.size =
          ((curr as unknown) as SanityVariantOptionServingSize)?.name || '';
      }

      if (
        curr &&
        (curr?.__typename === 'SanityVariantOptionServingType' ||
          ((curr as unknown) as SanityVariantOptionServingType)._type ===
            'variantOptionServingType')
      ) {
        accumulator.servingType =
          ((curr as unknown) as SanityVariantOptionServingType).name || '';
      }

      return accumulator;
    },
    supplementOptionObject
  );

  if (updateVariant) {
    Object.assign(variant, supplementOptionObject);
  }

  return supplementOptionObject as SupplementOptions;
}

// #region internal
/**
 * Creates a flattened SupplementVariant from CMS data for consumption by the UI
 * @param variant
 * @param productVariantMap
 * @param initialVariantGroup
 * @param initialSku
 */
function getFlattenedVariant(
  variant: ProductVariant,
  productVariantMap: SupplementProductVariantMap,
  initialVariantGroup: VariantGroupSelectorProps | undefined,
  initialSku: Maybe<string>
) {
  flattenSupplementVariantOptions(variant);

  const {
    flavor,
    size,
    servingType,
    recurring,
  } = variant as SupplementProductVariant;

  if (!(variant as SupplementProductVariant).text) {
    (variant as SupplementProductVariant).text = `${size} ${servingType}`;
  }

  // Create a grouping identifier for supplement product; flavor, size, servingType
  const variantType = `${flavor?.name || ''}${size || ''}${servingType || ''}${
    (variant as SupplementProductVariant).text || ''
  }`;

  if (!productVariantMap[variantType]) {
    productVariantMap[variantType] = {
      subscriptionOptions: [],
      standard: undefined as any,
    };
  }

  // If the variant doesn't have recurring set, then it is a standard variant (non-subscription)
  // or this variant is part of a bundle
  if (!recurring) {
    productVariantMap[
      variantType
    ].standard = variant as SupplementProductVariant;
    // Add subscription options for group
  } else {
    productVariantMap[variantType].subscriptionOptions.push(
      variant as SupplementProductVariant
    );
  }

  const product = {
    description: flavor?.name || '',
    color: flavor?.colorSwatch || '',
  } as VariantGroupSelectorProps;

  if (!initialVariantGroup && initialSku === variant?.sku) {
    initialVariantGroup = product;
  }

  return {
    initialSupplementVariantGroup: initialVariantGroup,
    supplementProduct: product,
  };
}
// #endregion

export type ImageAwareObject =
  | {
      image?: SanityImage;
    }
  | undefined;

export const getVariantImage = (
  variant: ImageAwareObject
): SanityImage | undefined => variant?.image;
